import { LogLevel } from '../logging/LogLevel';
import { EnumTools } from '../utilities';
import { Environment } from './Environment';

declare global {
  interface Window {
    AppSettings: any;
  }
}

window.AppSettings = window.AppSettings || {};

export class AppSettingsBase {
  // environment
  public nodeEnv: string | undefined = process.env.NODE_ENV; // NODE env, not app env: 'development' | 'test' | 'production'
  public environmentStr?: string = process.env.REACT_APP_ENV;
  public environment: Environment = EnumTools.parseEnum(Environment, process.env.REACT_APP_ENV || '');
  public websiteName?: string = process.env.REACT_APP_WEBSITE_NAME || '';
  public version?: string = process.env.REACT_APP_VERSION;

  // authentication
  public aadTenantId?: string = process.env.REACT_APP_AAD_TENANT_ID;

  // loggging
  public logLevelStr?: string = process.env.REACT_APP_LOG_LEVEL;
  public logLevel: LogLevel = EnumTools.parseEnum(LogLevel, process.env.REACT_APP_LOG_LEVEL || 'warning');

  // instrumentation
  public aiConnectionString?: string = process.env.REACT_APP_AI_CONNECTION_STRING;
  public debugMode: boolean = false;

  /**
   * Populate any calculated values in the constructor.
   */
  constructor() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.debugMode = urlParams.has('debug');
  }

  /**
   * Initialize is called during application startup. This avoids us having to import the class
   * at least once for the log method to be stamped on the window object.
   */
  public initialize() {
    window.AppSettings.log = () => {
      console.log('AppSettings', this);
      console.log('process.env', process.env);
    };
  }
}

export const baseAppSettings = new AppSettingsBase();
