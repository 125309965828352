/**
 * A collection of helper methods for working with enums.
 *
 * @export
 * @class EnumTools
 */
export class EnumTools {
  /**
   * A generic method for converting a string into the specified enum type.
   *
   * @template T
   * @param {T} type
   * @param {string} str
   * @returns {T[keyof T]}
   * @memberof EnumTools
   */
  public static parseEnum<T>(type: T, str: string): T[keyof T] {
    const casted = str as keyof T;
    return type[casted];
  }
}
