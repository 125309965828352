import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { telemetryService } from '..';
import { baseAppSettings } from '../configuration';
import { ITelemetryEvent } from '../instrumentation/ITelemetryEvent';
import { IThirdPartyTelemetryEventType } from '../instrumentation/IThirdPartyTelemetryEventType';

export interface ILogOptions {
  console?: boolean;
}

export class LogService {
  private defaultLogOptions: ILogOptions = { console: false };
  private defaultLogProperties: { [key: string]: any } = {};
  private getConsolidatedOptions = (options?: ILogOptions): ILogOptions => {
    return { ...this.defaultLogOptions, ...options };
  };

  public logInfo(message: string, customProperties?: ICustomProperties, options?: ILogOptions) {
    if (telemetryService.ai) {
      telemetryService.ai.trackTrace({ message, properties: this.defaultLogProperties }, customProperties);
    }

    this.consoleLog(message, customProperties, options);
  }

  public logError(message: string, customProperties?: ICustomProperties, options?: ILogOptions) {
    if (telemetryService.ai) {
      telemetryService.ai.trackException({
        error: new Error(message),
        properties: { ...this.defaultLogProperties, ...customProperties }
      });
    }

    this.consoleLog(message, { ...{ LogLevel: 'error' }, ...customProperties }, options);
  }

  public logEvent(properties: ITelemetryEvent, options?: ILogOptions) {
    if (telemetryService.ai) {
      telemetryService.ai.trackEvent({ name: properties.Name, properties: this.defaultLogProperties }, properties);
    }

    this.consoleLog(properties.name, properties, options);
  }

  public logThirdPartyEvent(properties: IThirdPartyTelemetryEventType, options?: ILogOptions) {
    if (telemetryService.ai) {
      telemetryService.ai.trackEvent({ name: properties.Name, properties: this.defaultLogProperties }, properties);
    }

    this.consoleLog(properties.name, properties, options);
  }

  public logMetric(
    name: string,
    average: number,
    min?: number,
    max?: number,
    sampleCount?: number,
    customProperties?: ICustomProperties,
    options?: ILogOptions
  ) {
    if (telemetryService.ai) {
      telemetryService.ai.trackMetric(
        { name, average, min, max, sampleCount, properties: this.defaultLogProperties },
        customProperties
      );
    }

    this.consoleLog(`${name}, ${average}, ${min}, ${max}, ${sampleCount}`, customProperties, options);
  }

  public setContext(properties: { [key: string]: any }, clearPrevious?: boolean): void {
    telemetryService.setContext(properties, clearPrevious);
  }

  public setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
    telemetryService.ai.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
  }

  public clearAuthenticatedUserContext() {
    telemetryService.ai.clearAuthenticatedUserContext();
  }

  public enableCookie(enable: boolean) {
    telemetryService.ai.getCookieMgr().setEnabled(enable);
  }

  public getDefaultLogProperties(): { [key: string]: any } {
    return this.defaultLogProperties || {};
  }

  public setDefaultLogProperties(properties: { [key: string]: any }, clearPrevious?: boolean): void {
    if (clearPrevious) {
      this.defaultLogProperties = {};
    }

    properties = properties || {};
    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        this.defaultLogProperties[key] = properties[key];
      }
    }
  }

  private consoleLog(message: string, customProperties?: ICustomProperties, options?: ILogOptions) {
    options = this.getConsolidatedOptions(options);

    if (baseAppSettings.debugMode || (options && options.console)) {
      if (customProperties && customProperties.LogLevel === 'error') {
        console.error(message, customProperties);
      } else {
        console.log(message, customProperties);
      }
    }
  }
}

export const logService = new LogService();
