import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { baseAppSettings } from '../../common';
import history from '../../history';

export class TelemetryService {
  private contextProps: { [key: string]: any } = {};

  public aiReactPlugin = new ReactPlugin();
  public ai = new ApplicationInsights({
    config: {
      connectionString: baseAppSettings.aiConnectionString,
      extensions: [this.aiReactPlugin],
      extensionConfig: {
        [this.aiReactPlugin.identifier]: { history: history }
      },
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true,
      // by default App Insights shouldn't use cookies until the user has granted consent - check WcpCookieConsent control
      disableCookiesUsage: true,
      correlationHeaderExcludedDomains: [
        'signupppe.microsoft.com',
        'signup.microsoft.com',
        'uhf.microsoft.com',
        'uhf-ppe.microsoft.com'
      ]
    }
  });

  constructor() {
    try {
      this.ai.loadAppInsights();
      this.ai.addTelemetryInitializer(this.telemetryInitializer);
    } catch (error) {
      console.error(error);
    }
  }

  public getContext(): { [key: string]: any } {
    return this.contextProps || {};
  }

  public setContext(properties: { [key: string]: any }, clearPrevious?: boolean): void {
    if (clearPrevious) {
      this.contextProps = {};
    }

    properties = properties || {};
    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        this.contextProps[key] = properties[key];
      }
    }
  }

  private telemetryInitializer = (envelope: ITelemetryItem): boolean | void => {
    envelope.baseData = envelope.baseData || {};
    envelope.baseData.properties = envelope.baseData.properties || {};
    const properties = envelope.baseData.properties;

    const props = this.getContext();
    for (const key in props) {
      if (props.hasOwnProperty(key)) {
        properties[key] = props[key];
      }
    }
  };
}

export const telemetryService = new TelemetryService();
